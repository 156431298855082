import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "collapse show" }
const _hoisted_6 = { class: "card-body border-top p-9" }
const _hoisted_7 = { class: "row mb-6" }
const _hoisted_8 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_9 = { class: "col-lg-8 fv-row" }
const _hoisted_10 = { class: "fv-plugins-message-container" }
const _hoisted_11 = { class: "fv-help-block" }
const _hoisted_12 = { class: "row mb-6" }
const _hoisted_13 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_14 = { class: "col-lg-8 fv-row" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-lg-6 fv-row fv-plugins-icon-container" }
const _hoisted_17 = { class: "fv-plugins-message-container" }
const _hoisted_18 = { class: "fv-help-block" }
const _hoisted_19 = { class: "col-lg-6 fv-row fv-plugins-icon-container" }
const _hoisted_20 = { class: "fv-plugins-message-container" }
const _hoisted_21 = { class: "fv-help-block" }
const _hoisted_22 = { class: "row mb-6" }
const _hoisted_23 = { class: "col col-form-label fw-bold fs-6" }
const _hoisted_24 = { class: "col-lg-8 fv-row" }
const _hoisted_25 = { class: "fv-plugins-message-container" }
const _hoisted_26 = { class: "fv-help-block" }
const _hoisted_27 = {
  class: "col-lg-12 fw-normal fs-6 mb-4",
  style: {"white-space":"break-spaces"}
}
const _hoisted_28 = { class: "col-lg-8 fv-row" }
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col fv-row fv-plugins-icon-container" }
const _hoisted_31 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_down = _resolveComponent("arrow-down")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_FamiliaReadOnly = _resolveComponent("FamiliaReadOnly")!
  const _component_ServiciosTabla = _resolveComponent("ServiciosTabla")!
  const _component_ServiciosColectivosTabla = _resolveComponent("ServiciosColectivosTabla")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_Page = _resolveComponent("Page")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_Page, { showBack: "" }, {
    actions: _withCtx(() => [
      _createVNode(_component_el_dropdown, { class: "mx-3" }, {
        dropdown: _withCtx(() => [
          _createVNode(_component_el_dropdown_menu, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_dropdown_item, {
                onClick: _ctx.openServicioColectivoPicker,
                loading: _ctx.crearServicioProgramado
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("dialogs.asignarAServicioColectivo.button")), 1)
                ]),
                _: 1
              }, 8, ["onClick", "loading"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_button, { type: "default" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("formularios.servicioDetalle.acciones.button")) + " ", 1),
              _createVNode(_component_el_icon, { class: "el-icon--right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_arrow_down)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: _ctx.guardarMenor,
        loading: _ctx.guardaDetalle
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("formularios.menor.guardar.button")), 1)
        ]),
        _: 1
      }, 8, ["onClick", "loading"])
    ]),
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_el_tabs, {
        type: "border-card",
        modelValue: "datosMenor"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            name: "datosMenor",
            label: _ctx.$t('formularios.menor.label')
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("formularios.menor.label")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("formularios.familiaDetalle.tratamiento.label")), 1),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_el_select, {
                          name: "tratamiento",
                          class: "w-100",
                          modelValue: _ctx.tratamiento,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tratamiento) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tratamientos, (tratamiento, i) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: i,
                                label: tratamiento.text,
                                value: tratamiento.id
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"]),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_ErrorMessage, { name: "tratamiento" })
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("formularios.familiaDetalle.nombre.label")), 1),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(_component_el_input, {
                              type: "text",
                              name: "nombre",
                              placeholder: " ",
                              modelValue: _ctx.nombre,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nombre) = $event))
                            }, null, 8, ["modelValue"]),
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, [
                                _createVNode(_component_ErrorMessage, { name: "nombre" })
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_19, [
                            _createVNode(_component_el_input, {
                              type: "text",
                              name: "apellidos",
                              placeholder: " ",
                              modelValue: _ctx.apellidos,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.apellidos) = $event))
                            }, null, 8, ["modelValue"]),
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("div", _hoisted_21, [
                                _createVNode(_component_ErrorMessage, { name: "apellidos" })
                              ])
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t("formularios.familiaDetalle.fechaNacimiento.label")), 1),
                      _createElementVNode("div", _hoisted_24, [
                        _createVNode(_component_el_date_picker, {
                          format: "DD/MM/YYYY",
                          name: "fechaNacimiento",
                          modelValue: _ctx.fechaNacimiento,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fechaNacimiento) = $event)),
                          type: "date",
                          class: "w-100 custom-datepicker",
                          "disabled-date": (date) => date.getTime() > Date.now()
                        }, null, 8, ["modelValue", "disabled-date"]),
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("div", _hoisted_26, [
                            _createVNode(_component_ErrorMessage, { name: "fechaNacimiento" })
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["label"]),
          (_ctx.familia)
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 0,
                name: "datosFamilia",
                label: _ctx.$t('formularios.servicioDetalle.datosFamilia')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FamiliaReadOnly, { familia: _ctx.familia }, null, 8, ["familia"])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_el_tab_pane, {
            label: _ctx.$t('formularios.familiaDetalle.servicios'),
            lazy: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ServiciosTabla, {
                servicios: _ctx.menoresServicios,
                class: "mb-10"
              }, null, 8, ["servicios"]),
              _createVNode(_component_ServiciosColectivosTabla, { servicios: _ctx.menoresServiciosColectivos }, null, 8, ["servicios"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      })), [
        [_directive_loading, _ctx.cargaDetalle]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_el_dialog, {
        modelValue: _ctx.dialogVisible,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dialogVisible) = $event)),
        title: _ctx.$t('dialogs.asignarAServicioColectivo.title'),
        width: "30%"
      }, {
        footer: _withCtx(() => [
          _createElementVNode("span", _hoisted_31, [
            _createVNode(_component_el_button, { onClick: _ctx.closeServicioColectivoPicker }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("dialogs.asignarAServicioColectivo.cancelButton")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.asignarServicioColectivo,
              disabled: !_ctx.dialogSelected
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("dialogs.asignarAServicioColectivo.okButton")), 1)
              ]),
              _: 1
            }, 8, ["onClick", "disabled"])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("dialogs.asignarAServicioColectivo.texto")), 1),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.dialogSelected,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialogSelected) = $event)),
                  class: "w-100",
                  placeholder: 
                _ctx.$t('dialogs.reasignarAServicioColectivo.placeholder')
              ,
                  "no-data-text": 
                _ctx.$t('dialogs.reasignarAServicioColectivo.sinServiciosColectivos')
              ,
                  "popper-class": "w-auto"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dialogData, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.id,
                        label: item.nombre,
                        value: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.nombre), 1)
                        ]),
                        _: 2
                      }, 1032, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder", "no-data-text"])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "title"])), [
        [_directive_loading, _ctx.dialogLoading]
      ])
    ]),
    _: 1
  }))
}