
import { showBackendError } from "@/assets/ts/_utils/ShowBackendError";
import FamiliaReadOnly from "@/components/familia/FamiliaReadOnly.vue";
import ServiciosColectivosTabla from "@/components/servicios/TablaColectivosFamilias.vue";
import ServiciosTabla from "@/components/servicios/TablaFamilias.vue";
import comarcas from "@/core/data/comarcas";
import tratamientos from "@/core/data/tratamientos";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Page from "@/layout/Page.vue";
import {
  AuthModule,
  FamiliasListadoModule,
  MenorModule,
  ServicioListado,
  ServiciosProgramadosModule,
} from "@/store/modules";
import { ArrowDown } from "@element-plus/icons-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import {
  ComputedRef,
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface FormState {
  tratamiento: string;
  nombre: string;
  apellidos: string;
  fechaNacimiento: Date;
}

export default defineComponent({
  name: "menor-detalle",
  components: {
    ErrorMessage,
    ServiciosTabla,
    ServiciosColectivosTabla,
    Page,
    FamiliaReadOnly,
    ArrowDown,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const token = route.params.id as string;
    const store = useStore();
    const serviciosProgramadosModule = getModule(
      ServiciosProgramadosModule,
      store
    );
    const module = getModule(MenorModule, store);
    const authModule = getModule(AuthModule, store);
    const familiaModule = getModule(FamiliasListadoModule, store);

    const comarcaId = authModule.user.comarcaId;

    const schema = Yup.object({
      tratamiento: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.tratamiento.label")),
      nombre: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.nombre.label")),
      apellidos: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.apellidos.label")),
      fechaNacimiento: Yup.date().required(),
    });

    const { resetForm, handleSubmit } = useForm<FormState>({
      validationSchema: schema,
    });

    const cargaDetalle = ref<boolean>(false);
    const fetchMenor = async function () {
      cargaDetalle.value = true;
      await module
        .fetchById({ comarcaId: comarcaId, menorId: token })
        .catch((err) => {
          if (typeof err === "object" && err?.error?.statusCode < 500) {
            showBackendError(t, err.error);
          } else {
            Swal.fire({
              text: t("formularios.menor.cargar.error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        })
        .finally(() => {
          cargaDetalle.value = false;
        });
    };

    const guardaDetalle = ref<boolean>(false);
    const guardarMenor = handleSubmit(async (values) => {
      guardaDetalle.value = true;

      await module
        .update({
          comarcaId: comarcaId,
          menorId: token,
          data: values,
        })
        .then(() => {
          return Swal.fire({
            text: t("formularios.menor.guardar.mensaje.exito"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            router.replace("/listados/menores");
          });
        })
        .catch((err) => {
          if (typeof err === "object" && err?.error?.statusCode < 500) {
            showBackendError(t, err.error);
          } else {
            Swal.fire({
              text: t("formularios.menor.cargar.error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        })
        .finally(() => {
          guardaDetalle.value = false;
        });
    });

    onMounted(() => {
      if (token) fetchMenor();
    });
    const menor = computed(() => module.menor);
    const familia = computed(() => module.menor?.familia);
    const comarcaIdV = computed(() => familia.value?.comarcaId);

    watch(menor, () => {
      if (!menor.value) return;

      resetForm({
        values: {
          tratamiento: menor.value.tratamiento,
          nombre: menor.value.nombre,
          apellidos: menor.value.apellidos,
          fechaNacimiento: menor.value.fechaNacimiento,
        },
      });

      setCurrentPageBreadcrumbs(
        [menor.value.nombre, menor.value.apellidos].join(" "),
        [
          t("menu.planCorresponsables"),
          { title: t("menu.menores"), path: "/listados/menores" },
        ]
      );
    });

    const menoresServicios = computed(() => {
      return (
        menor.value?.servicios?.filter(
          (servicio) => servicio.servicioProgramadoId === null
        ) ?? []
      ).map((ele) => {
        return {
          ...ele,
          familia: menor.value?.familia,
          menores: menor.value ? [menor.value] : [],
        };
      });
    });
    const menoresServiciosColectivos: ComputedRef<ServicioListado[]> = computed(
      () => {
        return (
          menor.value?.servicios?.filter(
            (servicio) => servicio.servicioProgramadoId !== null
          ) ?? []
        ).map((ele) => {
          return {
            ...ele,
            familia: menor.value?.familia,
            menores: menor.value ? [menor.value] : [],
          };
        });
      }
    );

    const { value: tratamiento } = useField("tratamiento");
    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: fechaNacimiento } = useField("fechaNacimiento");

    const crearServicioProgramado = ref(false);
    const dialogVisible = ref(false);
    const dialogLoading = ref(false);
    const dialogSelected = ref<string>();

    return {
      openServicioColectivoPicker: async () => {
        dialogVisible.value = true;
        dialogLoading.value = true;
        try {
          await serviciosProgramadosModule.fetchListado(
            comarcaIdV.value as string
          );
        } catch (err) {
          Swal.fire({
            text: t("formularios.serviciosProgramadosListado.cargar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          dialogLoading.value = false;
        }
      },
      closeServicioColectivoPicker: async () => {
        dialogVisible.value = false;
        dialogSelected.value = undefined;
      },
      asignarServicioColectivo: async () => {
        const comarcaId = comarcaIdV.value as string;
        const idServicioColectivo = dialogSelected.value as string;
        const idFamilia = familia.value?.id as string;
        const idMenor = token as string;

        if (!comarcaId || !idServicioColectivo) return;

        try {
          await familiaModule.addServioProgramadoMenor({
            comarcaId: !authModule.user.comarcaId ? undefined : comarcaId,
            familiaId: idFamilia,
            menorId: idMenor,
            servicioProgramadoId: idServicioColectivo,
          });
          await familiaModule.fetchServiciosById({
            comarcaId: comarcaId,
            id: idFamilia,
          });
          dialogVisible.value = false;
          dialogSelected.value = undefined;
          await Swal.fire({
            text: t("dialogs.añadirSolicitudServicioProgramado.success"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-light-primary",
            },
          });
        } catch (error) {
          dialogVisible.value = false;
          dialogSelected.value = undefined;
          Swal.fire({
            text: t("dialogs.añadirSolicitudServicioProgramado.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      },
      crearServicioProgramado,
      dialogVisible,
      dialogLoading,
      dialogSelected,
      dialogData: computed(() =>
        serviciosProgramadosModule.servicios.filter((servicio) => {
          return (
            servicio.inscripcionAbierta &&
            new Date(servicio.servicio.finPrevisto) > new Date()
          );
        })
      ),
      tratamientos,
      schema,
      cargaDetalle,
      guardarMenor,
      guardaDetalle,
      familia,
      tratamiento,
      nombre,
      apellidos,
      fechaNacimiento,
      isAdmin: !comarcaId,
      comarcas,
      menoresServicios,
      menoresServiciosColectivos,
    };
  },
});
